import React from 'react';

 const BookMarks = ( {bookmarks} ) => {
  return (
    <div className='d-flex bookmarks-container'>
        {
          bookmarks?.map((bookmark, index) => {
           return  <a key={index} href={`#${bookmark?.bookmarkRef}`}>{bookmark?.bookmarkName}</a>
          })
        }
    </div>
  )
}
export default BookMarks;