import React from 'react'
import { Link } from 'gatsby'
import BrocherSidebar from './BrocherSidebar'

const Brochers = ( {data} ) => {

    return (
    
        <section className=' services-details-area component-parent'>
            <div className='heading-container intro-container container text-center mb-4'>
                <h2 className='font-outfit'>{data?.heading}</h2>
                <img src={data?.image?.url} alt='secure transfusion solutions' />
            </div>  
            <div className='container'>
                <div className="row">
                    {
                       data?.tiles?.map((tile)=>{
                        return(
                            <div className="col-lg-6 col-md-6">
                            <div className="single-industries-serve-box">
                                <div className="icon">
                                    <img src={tile?.icon?.url} alt='icon' />
                                </div>
                                {tile?.detail}
                            </div>
                            </div>
                        )
                       })
                    }
                    
                </div>

                <div className='d-flex justify-content-center' id='Panacea-btn'>
                    <div className="">
                        <Link 
                            to="/history" 
                            activeClassName="active"
                            className="default-btn"
                        >
                            <i className="flaticon-right"></i>Successful Deployments<span></span>
                        </Link>
                    </div>
                    <BrocherSidebar />
                </div>
            </div>
        </section>
    )
}

export default Brochers