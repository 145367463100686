import React from 'react'
import Layout from '../components/App/Layout'
import Navbar from '../components/App/Navbar'
import BookMarks from '../components/Common/BookMarks'
import Brochers from '../components/ServiceDetails/Brochers'
import Testimonials from '../components/Index/Testimonials'
import Faq from '../components/Common/FAQs'
import Slider from '../components/Common/Slider'
import Footer from '../components/App/Footer'
import ProductsIntro from '../components/products/ProductsIntro'
import ProductFeature from '../components/products/ProductFeature'
import Detail from '../components/products/Detail'
import Banner from '../components/HemaCode/Banner'
import Features from '../components/HemaCode/Features'
import Intro from '../components/products/Intro'
import Details from '../components/HemaCode/Details'
import Header from '../components/App/Header'


const Product = ( context ) => {
    const product  = context?.pageContext?.product
    const { metaTitle, metaDescription, slug, scriptTag } = product

    return (
      <div id='EDMS'>
      <Layout>
        <Header metaTitle={metaTitle} metaDescription={metaDescription} slug={slug} scriptTag={scriptTag?.data?.scriptTag} />
        <Navbar />
        {product?.bookmark && <BookMarks bookmarks={product?.bookmark} />}
        {(product?.bannerDetail && product?.bannerDetail.length > 0) && (<ProductsIntro video={product?.videoId} intro={product?.Intro} bannerDetail={product?.bannerDetail}/>)}
        {(product?.bannerDetailTheme2) && (<Banner bannerDetail={product?.bannerDetailTheme2} />)}
        {(product?.introTheme2 && product?.introTheme2.length > 0) && (<Intro intro={product?.introTheme2} />)}
        {(product?.detailsTheme2 && product?.detailsTheme2.length > 0) && (<Detail details={product?.detailsTheme2} />)}
        {(product?.featureDetailTheme2 && product?.featureDetailTheme2.length > 0) && (<Features featureDetail= {product?.featureDetailTheme2} title={product?.activeTitle} />)}
        {(product?.WhyUs) && (<Brochers data={product?.WhyUs}/>)}
        {(product?.productFeatures && product?.productFeatures.length > 0) && <ProductFeature featureHeading={product?.featureHeading} featureDesc={product?.featureDescription?.data?.featureDescription} features={product?.productFeatures} video={product?.videoId} />}
        {(product?.featuresTheme2) && (<Details featureHeading={product?.featureHeading} featureDesc={product?.featureDescription?.data?.featureDescription} data={product?.featuresTheme2} />)}
        {(product?.testimonial) && (<Testimonials testimonial={product?.testimonial}/>)}
        {(product?.faqs) && (<Faq faqs={product?.faqs} />)}
        <Slider activeTitle={product?.activeTitle} />
        <Footer />
      </Layout>
      </div>
    )
}

export default Product