import React from 'react'
import { Link } from 'gatsby'

const Products = ({ data }) => {
    return (
        <div className='product-container m-3'>
            <div className='header-container'>
                <div className='product-img-container '>
                    <img src={(data?.img?.url) ? (data?.img?.url) : (data?.img)} style={{ width: `${data.size}%` }} alt={data?.imgTag ? data?.imgTag : 'slider-img'} />
                    <h5 className='product-title font-weight-bold'>{data?.title}</h5>
                </div>
            </div>
            <p className='product-title-description'>{data?.titleDescription}</p>
            <div className='product-description'>
                <p>{data?.productDescription}</p>
            </div>
            <div>
                <Link
                    to={`../../${data?.link}`}
                    className="video-btn popup-youtube"
                >
                    <span className='view-more'>View</span>
                </Link>
            </div>

        </div>
    )
}
export default Products;