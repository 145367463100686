import React from 'react'
import ReactMarkdown from 'react-markdown'

const Intro = ({intro}) => {
    return (
        <section className='isbt-container'>
            <div className='isbt-intro container'>
               {
                intro.map((intro) => {
                    return(
                        <div className='intro-container'>
                            <h1 className='text-center font-outfit heading-one'>{intro?.heading}</h1>
                            <ReactMarkdown>{intro?.description?.data?.description}</ReactMarkdown>
                        </div>
                    )
                })
               }
            </div>
        </section>
    )
}

export default Intro