import React from 'react'


const Features = ({featureDetail, title}) => {
    
    return (
        <section className='isbt-container'>
            <div className='container'>
                <div className='heading p-3'>
                    <h2 className='font-outfit text-center heading-one'> Significance of Using {title} </h2>
                </div>
                <div className='row justify-content-center'>
                    {featureDetail?.map((feature, index) => {
                      return(
                        <div key={index} className='col-lg-4 col-md-6 col-sm-6'>
                            <div className='feature-card my-4'>
                                <div className='feature-image'>
                                    <img src={feature?.image?.url} alt={feature?.imgTag ? feature?.imgTag : 'feature'} />
                                </div>
                                <div className='feature-card-content'>
                                    <p>{feature?.title}</p>
                                    <span>{feature?.content}</span>
                                </div>
                            </div>
                        </div>
                      )
                    })}
                </div>
            </div>
        </section>
    )
}

export default Features