import React from 'react'
import { Helmet } from 'react-helmet'

const Header = ({ metaTitle, metaDescription, slug, scriptTag }) => {
  return (
    <Helmet>
        <title>{metaTitle}</title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-S2SVRK6LJB"></script>
        <script type="text/javascript">
        {`    window.dataLayer = window.dataLayer || [];
                function gtag(){
                dataLayer.push(arguments);
                }
                gtag('js', new Date());
                gtag('config', 'G-S2SVRK6LJB');`}
        </script>
        <meta name="description" content={metaDescription} />
        <meta name='robots' content='index, follow' />
        <link rel="canonical" href={`https://zaavia.net${slug}`} />
        {scriptTag ? 
            <script type="application/ld+json">
                {`
                    ${scriptTag}
                `}
            </script>
          :''
        }
        
    </Helmet>
)
}

export default Header