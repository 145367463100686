import React from 'react'

const Detail = ( {details} ) => {
    return (
        <section className="services-details-area ptb-100" style={{ backgroundColor: 'white' }}>
            <div className="container">
                <div className='intro-container my-5'>
                    <h2 className='font-outfit text-center heading-one'>{details?.heading}</h2>
                    <p>{details?.desc}</p>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="services-details-desc">
                            {details?.feature?.map((feature) => {
                                return(
                                    <div className="content">
                                        <p className="content-heading">{feature?.head}</p>
                                        <span>{feature?.desc}</span>
                                    </div>
                                )
                            })
                        }
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 ">
                       {details?.feature?.img.map((img) => {
                        return(
                            <img className='pt-5 pl-5' src={img?.url} alt={img?.alternativeText} />
                        )
                       })}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Detail