import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';


function FeatureCard({ feature }) {

  const [isExpanded, setIsExpanded] = useState(false);
  
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };
  
  const truncateString = (str, num) => {
    return num >= str?.length ? str : str?.slice(0, num) + '...' 
  }
  const featureList = feature?.featuresList[0]?.featureList?.data?.featureList
  const renderFeatureList = () => {
    if (isExpanded) {
      return (
        <ul className='pl-0'>
            <ReactMarkdown>{featureList}</ReactMarkdown>
        </ul>
      );
    } else {
      return (
        (
          <ul className='pl-0'>
            <li><ReactMarkdown>{truncateString(featureList, 130)}</ReactMarkdown></li>
          </ul>
        )
      );
    }
  };
  
  return (
    <div className='col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12' >
      <div className={`features-card d-flex flex-column align-items-center ${(feature?.isViewMore) ? ' ' : "disable" } ${isExpanded ? 'active' : ''}`}>
        <div className="features-card-header">
          <img
            className="feature-card-img"
            src={feature?.featuresCard[0]?.img?.url}
            alt="icon"
          />
          <h2>{feature?.featuresCard[0]?.header}</h2>
        </div>
        <div className="card-list-container">
         {renderFeatureList()}
        </div>

        {isExpanded?
          <span className="view-more" onClick={toggleExpansion}>
            Back
          </span>:
          <div className='mt-auto'>
            {featureList.length > 130? 
            <span className='view-more' onClick={toggleExpansion}>
            View More 
          </span>:
              <span className='disable view-more'>View More</span>
          }
          </div>
          }
      
      </div>
    </div>
  );
}

export default FeatureCard;
