import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import shape from '../../assets/images/shape/shape1.svg'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: false,
    nav: true,
    autoplayHoverPause: true,
    autoplay: false,
    items: 1,
    dots: true,
    margin: 30,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ],
};

const Testimonials = ( { testimonial } ) => {

    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <div className="testimonials-area component-parent">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="testimonial" /> 
                        Testimonials
                    </span>
                    <h2>{testimonial?.heading}</h2>
                    <p>{testimonial?.quotes}</p>
                </div>

                {display ? <OwlCarousel 
                    className="testimonials-slides owl-carousel owl-theme"
                    {...options}
                > 
                        {testimonial?.testimonialSlider?.map((singleSlide)=>{
                            return(
                                <div className="single-testimonials-item">
                                    <p className='text-center'> {singleSlide?.quotations}</p>
                                <div className="client-info">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <img src={singleSlide?.clientImage?.url} alt="testimonial" />
                                        <div className="title">
                                            <h3>{singleSlide?.clientName}</h3>
                                            <span>{singleSlide?.clientDesignation}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                        })}

                </OwlCarousel> : ''}

                <div className="testimonials-view-btn text-center">
                    <Link to="/testimonials" className="default-btn">
                        <i className="flaticon-view"></i> 
                        Check Out All Reviews <span></span>
                    </Link>
                </div>
            </div>

            <div className="shape-img1">
                <img src={shape} alt="testimonial" />
            </div>
        </div>
    )
}

export default Testimonials;