import Loadable from '@loadable/component'
import Products from '../Common/Products'
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby'

const OwlCarousel = Loadable(() => import('react-owl-carousel3'))


const options = {
    loop: true,
    nav: true,
    dots: true,
    autoplay: true,
    items: 3,
    responsive: {
        0: {
            items: 1
        },
        768: {
            items: 2,
            dots: true,

        },
        1200: {
            items: 3,
            dots: true,
        }
    },
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ]
};

const Slider = ({ activeTitle }) => {

const query = graphql`
{
    strapiProductsSlider{
        product{
        prod_id
        title
        titleDescription
        productDescription
        size
        link
        img{
          url
          alternativeText
        }
      }
    }
  }
`

const data = useStaticQuery(query)
const {
    strapiProductsSlider
} = data
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (

        <div className='component-parent'>
            <div className='container'>
                <div className='text-center mb-5'>
                    <h5 className='font-outfit heading-one'>Other Products</h5>
                </div>

                {display &&

                    <OwlCarousel className="home-slides edms-slider products-slider owl-carousel owl-theme" {...options} >

                        {
                            strapiProductsSlider?.product
                                ?.filter(product => activeTitle !== product.title)
                                .map((product, index) => (<Products key={index} data={product} />))

                        }

                    </OwlCarousel>}

            </div>
        </div>

    )
}

export default Slider;