import React, { useState } from 'react';

const Faq = ({ faqs }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div id='faqs' className='bg-white component-parent' >
      <div className="faq-container container">
        <div className='header-container text-center pb-3'>
          <h3 className='font-outfit'>Frequently Asked Questions</h3>
        </div>
        {faqs?.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className={`faq-question d-flex justify-content-between align-items-center p-2 ${activeIndex === index ? 'bg-light-blue' : ''}`} onClick={() => toggleAnswer(index)}>
              <h4 className='font-outfit'> {faq.question}</h4>
              {
                activeIndex === index ? <i className='bx bx-chevron-up'></i> : <i className='bx bx-chevron-down'></i>
              }
            </div>
            <div className={`faq-answer p-2 ${activeIndex === index ? 'open' : ''}`}>
              
            {faq?.ans?.map((answer, index) => {
              return (
                <div key={index}>
                  <span>{answer?.children[0]?.text}
                  <ul>
                    {answer?.children?.map((list, listIndex) => {
                      return list?.children?.map((listItem, listItemIndex) => (
                        <li key={listItemIndex}>{listItem.text}</li>
                      ));
                    })}
                  </ul>
                  </span>
                </div>
              );
            })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
