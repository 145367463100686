import React from 'react'
import Pdf1 from "../../assets/Brochures/Functionalities.pdf"
import Pdf2 from "../../assets/Brochures/Panacea_Brochure.pdf"


const BrocherSidebar = () => {
    return(
        <div className='d-flex' >
            <div>
                <a href = {Pdf2} target = "_blank" rel='noopener noreferrer' className='default-btn'>View Brochure <i className='bx bxs-file-pdf'></i><span></span> </a>
            </div>
            <div>
                <a href = {Pdf1} target = "_blank" rel='noopener noreferrer' className='default-btn'>Panacea Functionalities <i className='bx bxs-file-pdf'></i><span></span> </a>
            </div>
        </div>
    )
}

export default BrocherSidebar