import React from 'react'
import { Link } from 'gatsby';
import ModalVideo from 'react-modal-video'

const Banner = ({ bannerDetail }) => {
    const [isOpen, setIsOpen] = React.useState(true);
    const openModal = () => {
        setIsOpen(!isOpen);
    }

    return (
        <React.Fragment>
            <ModalVideo
                channel='youtube'
                isOpen={!isOpen}
                videoId={bannerDetail.videoId}
                onClose={() => setIsOpen(!isOpen)}
            />
            <div className='banner-isbt isbt-container'>
                <div className='container'>
                    <div className='banner-item-content'>
                        <div className='banner-image p-4'>
                            <img src={bannerDetail?.image?.url || bannerDetail?.image } alt={bannerDetail?.image?.alternativeText || bannerDetail?.img_tag} />
                        </div>
                        <p className='font-outfit pt-2 banner-title'>{bannerDetail?.title}</p>
                        <div className="btn-box">
                            <Link to="/demo" className="default-btn">
                                <i className="flaticon-right"></i>
                                Free Demo
                            </Link>
                            <Link
                                to="#play-video"
                                onClick={e => { e.preventDefault(); openModal() }}
                                className="video-btn popup-youtube"
                            >
                                <i className="flaticon-google-play"></i> Watch Video
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Banner